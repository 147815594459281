import { getDashYMD } from "utilities";

export const formInitialValues = (data = {}) => ({
  publicId: data?.publicId ?? undefined,
  name: data?.name ?? '',
  description: data?.description ?? '',
  activityDate: data?.activityDate ?? getDashYMD(new Date()),
  isActive: true,
  achievementLibraryPublicId: data?.achievement?.achievementLibrary?.publicId ?? '',
  achievementRegionalPublicId: data?.achievement?.achievementRegional?.publicId ?? '',
  achievementParticipationPublicId: data?.achievement?.achievementParticipation?.publicId ?? '',
})