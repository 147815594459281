import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks";
import { axiosPrivate } from "services";
import { AUTH_URL } from "helper/UrlHelper";

export const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    await axiosPrivate.get(`${AUTH_URL}/v1/users/logout`).then(() => {
      setAuth({ status: "unauthenticated" });
      localStorage.removeItem("siswaku-id");
      localStorage.removeItem("siswaku-link");
      navigate("/");
    });
  };

  return logout;
};
