export const mappingDataOptions = data => {
    return data.map(val => {
        return {
            value: val.publicId ?? val.id,
            label: val.name ?? val.nama,
            ...val
        }
    })
}

export const mappingDataOptionsAddition = data => {
    let array = []
    array.push({
        value: '0',
        label: 'Semua'
    })

    data.forEach(val => {
        let obj = {
            value: val.publicId ?? val.id,
            label: val.name ?? val.nama,
            ...val
        }
        array.push(obj)
    })

    return array
}

export const mappingDataGender = data => {
    switch (data) {
        case 'L': return 'Laki-Laki'
        case 'P': return 'Perempuan'
        default: return ''
    }
}

export const mappingDataPeriode = data => {
    return data.map(val => {
        return {
            value: val.publicId,
            label: val.periode,
            ...val
        }
    })
}