import { CancelButton, EditButton, SaveButton } from "components";

export const HeaderSection = ({ permissions, data, type, setType, onSubmit, isSubmitting }) => {
  const statusSiswa = permissions.includes('STUDENT') ? 
                        <>Kelas {data?.classroom?.grade?.name}{data?.classroom?.name}</> : permissions.includes('ALUMNI') ?
                          <>Alumni</> : ''
  
  const handleClick = () => {
    switch (type) {
      case "Detail": setType("Form") 
                      break;
      case "Form": setType("Detail")
                    break;
      default: break;
    }
  }

  return (
    <div className="mb-5">
      <div className="sm:flex sm:flex-row">
        <div className="sm:flex-1">
          <h2 className="text-clobasoft-midnight-blue font-bold">{data?.name}</h2>
          {statusSiswa !== '' && 
            <label className="font-semibold text-clobasoft-midnight-blue">{statusSiswa}</label>
          }
        </div>
        <div>
          {permissions.includes('STUDENT') ?
            type === 'Detail' ? 
              <EditButton icon onClick={handleClick} className='mt-4 sm:mt-0 w-full sm:w-auto' /> : 
                <div className="flex flex-row mt-4 sm:mt-0">
                  <SaveButton 
                    icon 
                    type='submit' 
                    onClick={onSubmit} 
                    className='mr-2 flex-grow sm:flex-auto' 
                    disabled={isSubmitting} 
                    loading={isSubmitting} 
                  />
                  <CancelButton onClick={handleClick} className='ml-2 btn-light flex-grow sm:flex-auto' text='Batal'  />
                </div> : <></>
          }
        </div>
      </div>
    </div>
  )
}
