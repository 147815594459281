import { forwardRef } from "react";
import { PrintLayout, PrintTable } from "components";
import { mappingDataGender, getDashDMY, getFullDate } from "utilities";

const DataSiswa = ({ data }) => {
  const styleValue = "text-xs text-black pl-3 h-auto w-auto";
  const styleLabel = "w-32";

  return (
    <div className="mt-5">
      <div className="flex flex-row">
        <small className={styleLabel}>Nama Siswa</small>
        <small>:</small>
        <strong className={styleValue}>{data?.name}</strong>
      </div>
      <div className="flex flex-row">
        <small className={styleLabel}>NIS</small>
        <small>:</small>
        <strong className={styleValue}>{data?.nis}</strong>
      </div>
      <div className="flex flex-row">
        <small className={styleLabel}>Jenis Kelamin</small>
        <small>:</small>
        <strong className={styleValue}>
          {mappingDataGender(data?.gender)}
        </strong>
      </div>
      <div className="flex flex-row">
        <small className={styleLabel}>Tanggal Lahir</small>
        <small>:</small>
        <strong className={styleValue}>
          {data?.dateOfBirth ? getDashDMY(data?.dateOfBirth) : "-"}
        </strong>
      </div>
    </div>
  );
};

const KegiatanSiswa = ({ data }) => {
  return (
    <div className="mt-5">
      <div className="flex flex-row-reverse">
        <span className="text-xs font-bold px-4 py-2 border border-clobasoft-medium-gray w-auto">
          Total poin: {data?.totalPoint ?? 0}
        </span>
      </div>
      {data?.studentHasClasses?.map((val, index) => {
        if (val?.studentHasAchievementsAcc?.length > 0) {
          return (
            <>
              <div className="flex flex-row px-2 py-2 w-full mt-3">
                <div className="font-bold text-xs">
                  Kelas {val?.classroom?.grade?.name}-{val?.classroom?.name}
                </div>
                <div className="flex-grow font-bold text-xs text-right">
                  Tahun Ajaran: {val?.periode}
                </div>
              </div>
              <TabelKegiatanSiswa data={val?.studentHasAchievementsAcc} />
            </>
          );
        }
        return <></>;
      })}
    </div>
  );
};

const TabelKegiatanSiswa = ({ data }) => {
  const TABLE_HEADER = [
    { text: "No.", props: { width: "30px", fontSize: "11px" } },
    { text: "Tgl. Kegiatan", props: { width: "90px", fontSize: "11px" } },
    { text: "Kegiatan", props: { fontSize: "11px" } },
    { text: "Partisipasi", props: { width: "100px", fontSize: "11px" } },
    { text: "Jenis Kegiatan", props: { width: "100px", fontSize: "11px" } },
    { text: "Regional", props: { width: "120px", fontSize: "11px" } },
    { text: "Poin", props: { width: "50px", fontSize: "11px" } },
  ];

  const TABLE_BODY = [
    {
      field: (_, index) => index + 1 + ".",
      props: { textAlign: "center", fontSize: "11px" },
    },
    {
      field: (val) => (val?.activityDate ? getDashDMY(val?.activityDate) : "-"),
      props: { textAlign: "center", fontSize: "11px" },
    },
    { field: (val) => val?.name, props: { fontSize: "11px" } },
    {
      field: (val) => val?.participationName,
      props: { textAlign: "center", fontSize: "11px" },
    },
    {
      field: (val) => val?.achievementName,
      props: { textAlign: "center", fontSize: "11px" },
    },
    {
      field: (val) => val?.regional,
      props: { textAlign: "center", fontSize: "11px" },
    },
    {
      field: (val) => val?.point,
      props: { textAlign: "center", fontSize: "11px" },
    },
  ];

  return (
    <PrintTable data={data} tableHeader={TABLE_HEADER} tableBody={TABLE_BODY} />
  );
};

const TandaTangan = ({ data }) => {
  return (
    <div className="mt-14 text-xs" style={{ pageBreakInside: "avoid" }}>
      <div className="grid grid-cols-2 text-center mt-3">
        <div></div>
        <div>
          <label className="block">Yogyakarta, {getFullDate(new Date())}</label>
        </div>
      </div>
      <div className="grid grid-cols-2 text-center mt-1">
        <div>
          <div>
            <label className="block">Mengetahui,</label>
            <label>Kepala Sekolah</label>
          </div>
          <div className="h-28 grid content-end">
            <div className="font-bold underline">{data?.principal?.name}</div>
            <div>NIP. {data?.principal?.nip}</div>
          </div>
        </div>
        <div>
          <div>
            <label className="block">Menyetujui,</label>
            <label>Wakil Kepala Sekolah Bidang Kesiswaan</label>
          </div>
          <div className="h-28 grid content-end">
            <div className="font-bold underline">
              {data?.vicePrincipalOfStudent?.name}
            </div>
            <div>NIP. {data?.vicePrincipalOfStudent?.nip}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CetakRekap = forwardRef(({ data, dataSekolah }, ref) => {
  return (
    <div ref={ref}>
      <PrintLayout>
        <>
          {/* <-- TITLE --> */}
          <div className="text-center">
            <h3 className="font-bold">REKAP KEGIATAN SISWA</h3>
          </div>

          {/* <-- DATA SISWA --> */}
          <DataSiswa data={data} />

          {/* <-- DAFTAR KEGIATAN SISWA --> */}
          <KegiatanSiswa data={data} />

          {/* <-- TANDA TANGAN --> */}
          <TandaTangan data={dataSekolah} />
        </>
      </PrintLayout>
    </div>
  );
});
