import { CreateButton, RadioButton, RadioButtonGroup, SearchInput, Table } from "../.."

export const ListLayout = ({
    permissions = [],
    permissionCreate,
    customRightTopSection,
    customLeftTopSection,
    onSearchChange,
    onClickCreateButton,
    columns,
    data,
    loading,
    error,
    pagination,
    paginationRowsPerPageOptions,
    totalRows,
    handlePerRowsChange,
    handlePageChange,
    activeStatusFilter,
    activeValues,
    activeOnChange
}) => {
    return (
        <div>
            <div className='flex flex-col'>
                <div className='flex flex-row'>
                    <div className='flex-1'>
                        { customLeftTopSection ? customLeftTopSection() : <SearchInput onChange={onSearchChange} /> }
                    </div>
                    <div className="mt-1 ml-2">
                        { customRightTopSection ? customRightTopSection() : permissions.includes(permissionCreate) && <CreateButton type='button' onClick={onClickCreateButton} /> }
                    </div>
                </div>
                {activeStatusFilter && 
                    <div className="flex items-center mt-3">
                        <small className="mr-5 mt-1 font-medium">Status :</small>
                        <RadioButtonGroup>
                            <RadioButton 
                                checked={activeValues === 'none'}
                                label='Semua'
                                name='all'
                                onChange={activeOnChange}
                            />
                            <RadioButton 
                                checked={activeValues === 'true'}
                                label='Aktif'
                                name='true'
                                onChange={activeOnChange}
                            />
                            <RadioButton 
                                checked={activeValues === 'false'}
                                label='Tidak Aktif'
                                name='false'
                                onChange={activeOnChange}
                            />
                        </RadioButtonGroup>
                    </div>
                }
                <div className='mt-3'>
                    <Table 
                        columns={columns}
                        data={data}
                        loading={loading}
                        error={error}
                        pagination={pagination}
                        totalRows={totalRows}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                    />
                </div>
            </div>
        </div>
    )
}