export const Input = ({
    label,
    name,
    type,
    className,
    wrapperClassName,
    placeholder,
    value,
    readOnly,
    disabled,
    onChange,
    error,
    errorText,
    valid,
    validText,
    withSelect = false
}) => {
    return (
        <div className={wrapperClassName}>
            <small>
                {label}
            </small>
            <input
                name={name}
                type={type}
                className={`${withSelect && 'min-h-[38px]'} ${className ? className : 'input-primary'} ${disabled ? 'disabled:cursor-not-allowed' : ''}`}
                placeholder={placeholder}
                value={value}
                readOnly={readOnly}
                disabled={disabled}
                onChange={onChange}
            />
            {error && <div>
                <small className='validation-error-text'>
                    {errorText}
                </small>
            </div>}
            {valid && <div>
                <small className='validation-success-text'>
                    {validText}
                </small>
            </div>}
        </div>
    )
}
