import { Footer, Header } from "./__BaseLayoutComponents__";

export const BaseLayout = ({ children, title }) => {
  return (
    <div className="w-full overflow-y-auto h-full">
      <Header title={title} />
      <div
        className="mx-auto py-6 px-4 sm:px-6 lg:px-8"
        style={{ minHeight: title ? "calc(100% - 210px)" : "calc(100% - 126px)" }}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};
