import * as Yup from "yup";

export const formValidationSchema = () => {
  return Yup.object().shape({
    gender: Yup.string().required("Wajib dipilih").nullable(),
		phoneNo: Yup.string().required('No. Hp wajib diisi (cth. 08123456789)'),
    email: Yup.string()
      .email()
      .required("Email wajib diisi (cth. example@email.com)")
      .nullable(),
    street: Yup.string().required("Alamat wajib diisi"),
    address: Yup.object().shape({
      desaId: Yup.string().required("Wajib dipilih").nullable(),
      kecamatanId: Yup.string().required("Wajib dipilih").nullable(),
      kabupatenId: Yup.string().required("Wajib dipilih").nullable(),
      provinsiId: Yup.string().required("Wajib dipilih").nullable(),
    }),
  });
};
