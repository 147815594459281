class DropdownApi {
    async getGender() {
        const data = [
            {value: 'P', label: 'Perempuan'},
            {value: 'L', label: 'Laki-Laki'},
        ]
      
        return data
    }

    async getStatusPrestasi() {
        const data = [
            {value: 0, label: 'Semua'},
            {value: 1, label: 'Pending'},
            {value: 2, label: 'Approved'},
            {value: 3, label: 'Revision'},
            {value: 4, label: 'Rejected'}
        ]

        return data
    }
}

export default new DropdownApi()