import { AchievementApi } from "api"
import { DataStatus, Input, Label, Select, TextArea } from "components"
import { useFormikContext } from "formik"
import { useEffect } from "react"
import { useQuery } from "react-query"
import { useAxiosPrivate } from "utilities"
import BerandaApi from "../__BerandaApi__"
import { formInitialValues } from "../__BerandaUtilities__"

const Form = ({ modal }) => {
  // Hooks
  const axiosPrivate = useAxiosPrivate()
  const { values, setValues, errors, touched, handleChange } = useFormikContext()

  // <--- useQuery --->
  const getJenisKegiatan = useQuery(
    ['beranda-atribut-jenis-kegiatan'],
    () => AchievementApi.getAchievementLibrary(axiosPrivate, { isActive: true, hasAchievement: true }),
    {enabled: modal.show}
  )

  const getRegional = useQuery(
    ['beranda-atribut-regional', values?.achievementLibraryPublicId],
    () => AchievementApi.getAchievementRegionals(axiosPrivate, { isActive: true, achievementLibraryPublicId: values?.achievementLibraryPublicId }),
    {enabled: Boolean(values?.achievementLibraryPublicId)}
  )

  const getPartisipasi = useQuery(
    ['beranda-atribut-partisipasi', values?.achievementLibraryPublicId, values?.achievementRegionalPublicId],
    () => AchievementApi.getAchievementParticipation(axiosPrivate, { isActive: true, achievementLibraryPublicId: values?.achievementLibraryPublicId, achievementRegionalPublicId: values?.achievementRegionalPublicId }),
    {enabled: Boolean(values?.achievementLibraryPublicId && values?.achievementRegionalPublicId)}
  )

  return (
    <div className="space-y-3">
      <Select 
        label='Jenis Kegiatan'
        placeholder='Pilih Jenis Kegiatan'
        name='achievementLibraryPublicId'
        defaultValue={getJenisKegiatan?.data?.find(item => item.value === values?.achievementLibraryPublicId)}
        onChange={val => setValues({
          ...values,
          achievementLibraryPublicId: val.value,
          achievementRegionalPublicId: '',
          achievementParticipationPublicId: ''
        })}
        options={getJenisKegiatan?.data ?? []}
        error={Boolean(errors?.achievementLibraryPublicId && touched?.achievementLibraryPublicId)}
        errorText={Boolean(errors?.achievementLibraryPublicId && touched?.achievementLibraryPublicId) && errors?.achievementLibraryPublicId}
        errorFetch={getJenisKegiatan.isError}
        errorFetchText={getJenisKegiatan?.error?.response?.data?.errorMessage[0]}
        loading={getJenisKegiatan.isFetching}
      />
      <Select 
        label='Regional'
        placeholder='Pilih Regional'
        name='achievementRegionalPublicId'
        defaultValue={getRegional?.data?.find(item => item.value === values?.achievementRegionalPublicId)}
        onChange={val => setValues({
          ...values,
          achievementRegionalPublicId: val.value,
          achievementParticipationPublicId: ''
        })}
        options={getRegional?.data ?? []}
        error={Boolean(errors?.achievementRegionalPublicId && touched?.achievementRegionalPublicId)}
        errorText={Boolean(errors?.achievementRegionalPublicId && touched?.achievementRegionalPublicId) && errors?.achievementRegionalPublicId}
        errorFetch={getRegional.isError}
        errorFetchText={getRegional?.error?.response?.data?.errorMessage[0]}
        loading={getRegional.isFetching}
        disable={!Boolean(values?.achievementLibraryPublicId)}
      />
      <Select 
        label='Partisipasi'
        placeholder='Pilih Partisipasi'
        name='achievementParticipationPublicId'
        defaultValue={getPartisipasi?.data?.find(item => item.value === values?.achievementParticipationPublicId)}
        onChange={val => setValues({
          ...values,
          achievementParticipationPublicId: val.value
        })}
        options={getPartisipasi?.data ?? []}
        error={Boolean(errors?.achievementParticipationPublicId && touched?.achievementParticipationPublicId)}
        errorText={Boolean(errors?.achievementParticipationPublicId && touched?.achievementParticipationPublicId) && errors?.achievementParticipationPublicId}
        errorFetch={getPartisipasi.isError}
        errorFetchText={getPartisipasi?.error?.response?.data?.errorMessage[0]}
        loading={getPartisipasi.isFetching}
        disable={!Boolean(values?.achievementLibraryPublicId && values?.achievementRegionalPublicId)}
      />
      <Input 
        label='Nama Kegiatan'
        name='name'
        type='text'
        value={values?.name}
        onChange={handleChange}
        error={Boolean(errors.name && touched.name)}
				errorText={Boolean(errors.name && touched.name) && errors.name}
      />
      <Input 
        label='Tanggal Kegiatan' 
        name='activityDate'
        type='date'
        value={values?.activityDate}
        onChange={handleChange}
        error={Boolean(errors.activityDate && touched.activityDate)}
        errorText={Boolean(errors.activityDate && touched.activityDate) && errors.activityDate}
      />
      <TextArea 
          label='Keterangan (opsional)'
          name='description'
          value={values.description}
          rows={4}
          onChange={handleChange}
          error={Boolean(errors.description && touched.description)}
          errorText={Boolean(errors.description && touched.description) && errors.description}
      />
      {modal.error && 
        <Label 
          type='error'
          text={modal.errorText}
        />
      }
    </div>
  )
}

export const FormSection = ({ modal, studentPublicId }) => {
  const axiosPrivate = useAxiosPrivate()
  const { setValues, resetForm } = useFormikContext()

  useEffect(() => {
      if(modal.show && modal.type === 'create'){
          resetForm()
      }
  }, [modal.show])

  const getDetail = useQuery(
    ['prestasi-siswa-atribut-detail', studentPublicId, modal?.data?.publicId],
    () => BerandaApi.detail(axiosPrivate, studentPublicId, modal?.data?.publicId),
    {
      enabled: Boolean(modal.show && modal.type === 'update'),
      onSuccess: data => {
        setValues(formInitialValues(data))
      }
    }
  )

  if(modal.type === 'create'){
    return <Form modal={modal} />
  } else{
    if(getDetail.isError || getDetail.isFetching){
      return (
        <DataStatus 
          loading={getDetail.isFetching}
          loadingText='Memuat data kegiatan'
          text='Data kegiatan gagal dimuat'
        />
      )
    } else{
      return <Form modal={modal} />
    }
  }
}
