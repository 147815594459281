import React, { useState } from "react";
import { useQuery } from "react-query";
import BlogApi from "./__BlogAPI__";
import { DataStatus, Modal, Pagination } from "components";
import { ContentCard, DetailSection } from "./__BlogComponents__";

export const Blog = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    size: 4,
    isPublish: true,
    sortBy: "updatedAt",
    direction: "DESC",
  });
  const [modal, setModal] = useState({
    show: false,
    type: "read",
    data: {},
  });
  const [viewImageConfig, setViewImageConfig] = useState({
    show: false,
    index: 0,
  });

  const getList = useQuery(["blog-pageable", pagination], () =>
    BlogApi.getPageable(pagination)
  );

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "read",
      data: {},
    });
  const onReadButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "read",
      data: data,
    });

  const onImageClickHandler = (data, index) =>
    setViewImageConfig({
      show: true,
      index: index,
      url: data?.imageFileUrl,
    });

  if (getList.isFetching || getList.isError) {
    return (
      <DataStatus
        loading={getList.isLoading}
        loadingText="Memuat data pengumuman..."
        text={
          getList?.error?.response?.data?.errorMessage?.[0] ??
          "Pengumuman gagal dimuat"
        }
      />
    );
  } else {
    return (
      <>
        {getList?.data?.content?.length > 0 ? (
          <>
            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-4">
              {getList.data?.content?.map((val, index) => (
                <ContentCard
                  key={index}
                  data={val}
                  index={index}
                  onClickDetail={() => onReadButtonClickHandler(val)}
                  setViewImageConfig={setViewImageConfig}
                  viewImageConfig={viewImageConfig}
                  onImageClickHandler={onImageClickHandler}
                />
              ))}
            </div>
            {getList.data?.pageable?.totalElements <= 4 ? (
              <></>
            ) : (
              <div className="mt-5 flex flex-col justify-center items-center">
                <Pagination
                  currentPage={pagination?.page}
                  totalPages={getList.data?.pageable?.totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center">
            <label className="validation-error-text">
              Tidak Ada Pengumuman
            </label>
          </div>
        )}

        {/* <--- MODAL READ ---> */}
        {modal.type === "read" && modal.show && (
          <Modal
            id="modal-detail-blog"
            header="Detail Pengumuman"
            size="large"
            type="read"
            onHide={onHideModalHandler}
            show={Boolean(modal.show && modal.type === "read")}
          >
            <DetailSection modal={modal} />
          </Modal>
        )}
      </>
    );
  }
};
