import { Pagination as FPagination } from "flowbite-react"

export const Pagination = ({
  currentPage = 1,
  onPageChange,
  totalPages = 1
}) => {
  return (
    <FPagination 
      showIcons
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
    />
  )
}
