import { DataStatus, RowDetailLayout, Table, Tabs } from "components";
import { useQuery } from "react-query";
import {
  getDashDMY,
  getDashDMYTime,
  statusConvertPrestasi,
  useAxiosPrivate,
} from "utilities";
import BerandaApi from "../__BerandaApi__";

const TabInformasiKegiatan = ({ data }) => {
  return (
    <>
      <RowDetailLayout label="Nama Kegiatan" text={data?.name} />
      <RowDetailLayout
        label="Partisipasi"
        text={data?.achievement?.achievementParticipation?.name}
      />
      <RowDetailLayout
        label="Jenis Kegiatan"
        text={data?.achievement?.achievementLibrary?.name}
      />
      <RowDetailLayout
        label="Regional"
        text={data?.achievement?.achievementRegional?.name}
      />
      <RowDetailLayout
        label="Tanggal Kegiatan"
        text={data?.activityDate ? getDashDMY(data?.activityDate) : "-"}
      />
      <RowDetailLayout label="Poin" text={data?.achievement?.point} />
      <RowDetailLayout
        label="Kelas"
        text={`${data?.studentHasClasses?.classroom?.grade?.name}-${data?.studentHasClasses?.classroom?.name}`}
      />
      <RowDetailLayout label="Keterangan" text={data?.description} />
    </>
  );
};

const TabLogKegiatan = ({ data }) => {
  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Keterangan",
      selector: (row) => row?.description,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Status",
      selector: (row) => statusConvertPrestasi(row?.status),
      width: "125px",
    },
    {
      name: "Tanggal Simpan",
      selector: (row) => getDashDMYTime(row?.createdAt),
      width: "160px",
      wrap: true,
    },
  ];

  return <Table columns={columns} data={data} />;
};

export const DetailSection = ({ data }) => {
  const axiosPrivate = useAxiosPrivate();
  const { studentPublicId, publicId } = data?.data;

  // useQuery
  const getDetail = useQuery(
    ["prestasi-siswa-atribut-detail", studentPublicId, publicId],
    () => BerandaApi.detail(axiosPrivate, studentPublicId, publicId),
    { enabled: Boolean(data.show && data.type !== "create") }
  );

  if (getDetail.isError || getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data kegiatan siswa..."
        text="Data kegiatan siswa gagal dimuat"
      />
    );
  } else {
    return (
      <Tabs
        data={[
          {
            title: "Informasi Kegiatan",
            component: <TabInformasiKegiatan data={getDetail?.data} />,
          },
          {
            title: "Log Kegiatan",
            component: (
              <TabLogKegiatan data={getDetail?.data?.achievementLogs} />
            ),
          },
        ]}
      />
    );
  }
};
