import { FaPencilAlt } from "react-icons/fa";
import { Button, Spinner } from "../..";

export const EditButton = ({
    type,
    onClick,
    disabled,
    loading,
    icon,
    noText,
    className
}) => {
    return (
        <Button
            type={type}
            className={`${className} ${noText ?? 'px-5'} btn-warning flex flex-row justify-center items-center p-2`}
            disabled={disabled}
            loading={loading}
            onClick={onClick}
        >
            {loading ? <><Spinner />{noText ?? <span className="ml-2">Proses...</span>}</> : <>{icon && <FaPencilAlt className={`${noText ?? "mr-2"}`} />}{noText ?? <span>Ubah</span>}</>}
        </Button>
    )
}