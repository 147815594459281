import { DropdownApi, SchoolsApi, StudentApi } from "api";
import { CreateButton, DataStatus, DeleteButon, EditButton, ListLayout, Modal, PrintButton, Select, ViewButton } from "components";
import { Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { errorFetching, getDashDMY, statusConvertPrestasi, successFetching, useAuth, useAxiosPrivate } from "utilities"
import { DetailSection, FormSection, CetakRekap } from "./__BerandaComponents__";
import { formInitialValues, formValidationSchema } from "./__BerandaUtilities__";
import ReactToPrint from "react-to-print";
import BerandaApi from "./__BerandaApi__";

const ComponentToPrintWrapper = ({ cetakRekap, handleOnBeforePrint }) => {
  const componentRef = useRef()

  return (
    <>
      <ReactToPrint 
        trigger={() => (
          <PrintButton 
            icon
            text="Cetak Rekap"
            className='flex-grow'
            loading={cetakRekap.loading}
          />
        )}
        content={() => componentRef.current}
        removeAfterPrint={true}
        onBeforePrint={handleOnBeforePrint}
        documentTitle='Rekap Kegiatan Siswa'
      />
      <div style={{ display: "none" }}>
        <CetakRekap data={cetakRekap.data} dataSekolah={cetakRekap.dataSekolah} ref={componentRef} />
      </div>
    </>
  )
}

export const Beranda = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth()
  const permissions = auth.permissions

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: true,
    sortBy: 'id',
    direction: 'DESC',
    status: undefined,
    studentPublicId: ''
  })
  const [modal, setModal] = useState({
    show: false,
    type: 'create',
      errorText: '',
      error: false,
    data: {}
  })
  const [filter, setFilter] = useState({
    status: 0
  })
  const [studentData, setStudentData] = useState({
    studentPublicId: ''
  })
  const [cetakRekap, setCetakRekap] = useState({
    loading: false,
    data: {},
    dataSekolah: {}
  })

  useEffect(() => {
    if(studentData.studentPublicId !== ''){
      getStatusPrestasi.refetch()
      getPrestasi.refetch()
      getResume.refetch()
      getSchool.refetch()
    }
  }, [studentData.studentPublicId])
  

  // <--- Table's columns --->
  const columns = [
    {
      name: 'No',
      selector: (row, index) => ((getPrestasi.data?.pageable?.pageNumber - 1) * getPrestasi.data?.pageable?.pageSize) + index + 1,
      width: "50px" 
    },
    {
      name: 'Nama Kegiatan',
      selector: row => row?.name,
      minWidth: '200px',
      wrap: true
    },
    {
      name: 'Partisipasi',
      selector: row => row?.participationName,
      minWidth: '180px',
      wrap: true
    },
    {
      name: 'Jenis Kegiatan',
      selector: row => row?.achievementName,
      minWidth: '150px',
      wrap: true
    },
    {
      name: 'Regional',
      selector: row => row?.regional,
      minWidth: '150px',
      wrap: true
    },
    {
      name: 'Tgl. Kegiatan',
      selector: row => row?.activityDate ? getDashDMY(row?.activityDate) : '-',
      width: '110px',
      wrap: true
    },
    {
      name: 'Poin',
      selector: row => row?.point,
      width: '60px',
      wrap: true
    },
    {
      name: 'Kelas',
      selector: row => row?.classroomName,
      width: '70px',
      wrap: true
    },
    {
      name: 'Status',
      selector: row => statusConvertPrestasi(row?.status),
      width: '125px'
    },
    {
      name: 'Aksi',
      button: true,
      cell: (data) => <>
        <ViewButton icon noText onClick={() => onReadButtonClickHandler(data)} className="mr-1" />
        {
          permissions.includes('ACHIEVEMENT_U') &&
            (data?.status === 1 || data?.status === 3) && 
              <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />
        }
        {
          permissions.includes('ACHIEVEMENT_D') && 
            data?.status === 1 && 
              <DeleteButon icon noText onClick={() => onDeleteButtonClickHandler(data)} />
        }
      </>
    }
  ]

  // <--- useQuery --->
  const getDetailSiswa = useQuery(
    ['detail-siswa'],
    () => StudentApi.getDetailStudent(axiosPrivate),
    {onSuccess: res => {
      setStudentData({
        ...studentData,
        studentPublicId: res?.publicId
      })
    }}
  )

  const getStatusPrestasi = useQuery(
    ['status-prestasi', 'dropdown'],
    () => DropdownApi.getStatusPrestasi()
  )

  const getPrestasi = useQuery(
    ['prestasi-list', pagination, studentData?.studentPublicId],
    () => BerandaApi.getListPrestasi(axiosPrivate, studentData?.studentPublicId, pagination).catch(() => { return [] }),
    {enabled: Boolean(!getStatusPrestasi.isFetching && !getStatusPrestasi.isError && studentData?.studentPublicId !== '')}
  )

  const getResume = useQuery(
    ['resume-kegiatan'],
    () => BerandaApi.getResume(axiosPrivate),
    {
      enabled: Boolean(!getStatusPrestasi.isFetching && !getStatusPrestasi.isError && studentData?.studentPublicId !== ''),
      onSuccess: res => {
        setCetakRekap({...cetakRekap, data: res, loading: false})
      },
      onError: err => {
        errorFetching(err)
        setCetakRekap({...cetakRekap, data: {}, loading: false})
      }
    }
  )
  
  const getSchool = useQuery(
    ['data-sekolah'],
    () => SchoolsApi.getAccount(axiosPrivate),
    {
      onSuccess: res => {
        setCetakRekap({...cetakRekap, dataSekolah: res, loading: false})
      },
      onError: err => {
        errorFetching(err)
        setCetakRekap({...cetakRekap, loading: false})
      }
    }
  )

  // <--- useMutation --->
  const createPrestasi = useMutation((data) => BerandaApi.createPrestasi(axiosPrivate, studentData?.studentPublicId, data), {
    onSuccess: () => {
      getPrestasi.refetch()
    }
  })

  const updatePrestasi = useMutation(({studentHasAchievementPublicId, data}) => BerandaApi.updatePrestasi(axiosPrivate, studentData?.studentPublicId, studentHasAchievementPublicId, data), {
    onSuccess: () => {
      getPrestasi.refetch()
    }
  })

  const deletePrestasi = useMutation(id => BerandaApi.deletePrestasi(axiosPrivate, studentData?.studentPublicId, id), {
    onSuccess: () => {
      getPrestasi.refetch()
    }
  })

  // <--- Functions --->
  const handlePageChange = page => setPagination({...pagination, page: page})

  const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

  const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

  const onReadButtonClickHandler = data => setModal({ show: true, type: 'read', errorText: '', error: false, data: data})

  const onCreateButtonClickHandler = () => setModal({ show: true, type: 'create', errorText: '', error: false, data: {} })

  const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
  
  const onDeleteButtonClickHandler = data => setModal({ show: true, type: 'delete', errorText: '', error: false, data: data})

  // <--- Print --->
  const handleOnBeforePrint = () => {
    setCetakRekap({...cetakRekap, loading: true})
    getSchool.refetch()
    getResume.refetch()
  }

  if(getDetailSiswa.isFetching || getDetailSiswa.isError){
    return (
      <DataStatus 
        loading={getDetailSiswa.isFetching}
        loadingText='Memuat data...'
        text={`Gagal memuat data ${permissions.includes('STUDENT') ? 'siswa' : 'alumni'}`}
      />
    )
  } else {
    return (
      <>
        <div className="flex sm:flex-row flex-col-reverse">
          <div className="mt-3 sm:mt-0">
            <Select 
              label='Status'
              placeholder='Pilih Status'
              defaultValue={getStatusPrestasi?.data?.find(item => item.value === filter?.status)}
              onChange={val => {
                
                setPagination({
                    ...pagination,
                    status: val.value !== 0 ? val.value : undefined
                })
                setFilter({
                  ...filter,
                  status: val.value
                })
              }}
              options={getStatusPrestasi?.data ?? []}
              errorFetch={getStatusPrestasi.isError}
              errorFetchText={getStatusPrestasi?.error?.response?.data?.errorMessage[0]}
              loading={getStatusPrestasi.isFetching}
            />
          </div>
          <div className="flex-grow sm:grid sm:content-end sm:justify-items-end">
              <div className="flex flex-row space-x-3">
                {permissions.includes('ACHIEVEMENT_C') ?
                  <CreateButton 
                    type='button'
                    onClick={onCreateButtonClickHandler}
                    text='Tambah Kegiatan'
                    className='flex-grow'
                  /> : <></>}
                <ComponentToPrintWrapper 
                  cetakRekap={cetakRekap}
                  handleOnBeforePrint={handleOnBeforePrint}
                />
              </div>
            </div>
        </div>
        {/* <--- Table --->  */}
        <ListLayout 
          permissions={permissions}
          data={getPrestasi.data?.content}
          columns={columns}
          loading={Boolean(getDetailSiswa.isFetching || getPrestasi.isFetching)}
          error={getPrestasi.error?.response?.data?.errorMessage[0]}
          pagination={true}
          totalRows={getPrestasi.data?.pageable?.totalElements}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
          customRightTopSection={() => <></>}
          customLeftTopSection={() => <></>}
        />

        {/* <--- MODAL READ ---> */}
        {modal.type === 'read' && modal.show &&
          <Modal
              id='modal-detil-prestasi-siswa'
              header='Detil Kegiatan'
              size='medium'
              type='read'
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === 'read')}
          >
            <DetailSection data={modal} />
          </Modal>
        }

        {/* <--- MODAL CREATE ---> */}
        <Formik
          enableReinitialize
          initialValues={formInitialValues()}
          validationSchema={formValidationSchema}
          onSubmit={(values, { resetForm }) => {
            createPrestasi.mutateAsync(values).then(res => {
              resetForm()
              successFetching(res)
              onHideModalHandler()
            }).catch(err => {
              const errorMessage = errorFetching(err)
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage
              })
            })
          }}
        >
          {(formik) => {
            const { handleSubmit, resetForm } = formik

            return (
              <Modal
                id='modal-tambah-prestasi-siswa'
                header='Tambah Kegiatan'
                size='small'
                type='create'
                onHide={() => {
                  resetForm()
                  onHideModalHandler()
                }}
                show={Boolean(modal.show && modal.type === 'create')}
                onSubmit={handleSubmit}
                isSubmitting={createPrestasi.isLoading}
              >
                <FormSection modal={modal} />
              </Modal>
            )
          }}
        </Formik>

        {/* <--- MODAL UPDATE ---> */}
        <Formik
          enableReinitialize
          initialValues={formInitialValues(modal?.data)}
          validationSchema={formValidationSchema}
          onSubmit={(values, { resetForm }) => {
            updatePrestasi.mutateAsync({
              studentHasAchievementPublicId: values?.publicId,
              data: values
            }).then(res => {
              resetForm()
              successFetching(res)
              onHideModalHandler()
            }).catch(err => {
              const errorMessage = errorFetching(err)
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage
              })
            })
          }}
        >
          {(formik) => {
            const { handleSubmit, resetForm } = formik

            return (
              <Modal
                id='modal-ubah-prestasi-siswa'
                header='Ubah Kegiatan'
                size='small'
                type='update'
                onHide={() => {
                  resetForm()
                  onHideModalHandler()
                }}
                show={Boolean(modal.show && modal.type === 'update')}
                onSubmit={handleSubmit}
                isSubmitting={updatePrestasi.isLoading}
              >
                <FormSection modal={modal} studentPublicId={studentData?.studentPublicId} />
              </Modal>
            )
          }}
        </Formik>

        {/* <--- MODAL DELETE ---> */}
        <Modal
            id='modal-hapus-prestasi-siswa'
            header='Hapus Kegiatan'
            size='medium'
            type='delete'
            onHide={onHideModalHandler}
            show={Boolean(modal.show && modal.type === 'delete')}
            isSubmitting={deletePrestasi.isLoading}
            onSubmit={() => {
                deletePrestasi.mutateAsync(modal.data.publicId).then((res) => {
                    successFetching(res)
                    onHideModalHandler()
                }).catch(err => {
                    const errorMessage = errorFetching(err)
                    setModal({
                        ...modal,
                        error: true,
                        errorText: errorMessage
                    })
                })
            }}
        >
            <DetailSection data={modal} />
        </Modal>
      </>
    )
  }
}