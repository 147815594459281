import { DropdownApi, RegionalApi } from "api";
import { Input, Select, Tabs, TextArea } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { getDashDMY, useAxiosPrivate } from "utilities";
import { HeaderSection } from ".";

const TabInformasiUmum = ({ type }) => {
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  // <--- useQuery --->
  const getGender = useQuery(
    ["kesiswaan-atribut-gender"],
    () => DropdownApi.getGender(),
    { enabled: Boolean(type === "Form") }
  );

  return (
    <div className="space-y-5">
      <Input label="NIS (Nomor Induk Siswa)" value={values?.nis} disabled />
      <Input
        label="Tanggal Lahir"
        value={values?.dateOfBirth && getDashDMY(values?.dateOfBirth)}
        disabled
      />
      <Select
        label="Jenis Kelamin"
        placeholder="Pilih Jenis Kelamin"
        name="gender"
        defaultValue={getGender?.data?.find(
          (item) => item.value === values?.gender
        )}
        onChange={(val) =>
          setValues({
            ...values,
            gender: val.value,
          })
        }
        options={getGender?.data ?? []}
        error={Boolean(errors?.gender && touched?.gender)}
        errorText={Boolean(errors?.gender && touched?.gender) && errors?.gender}
        errorFetch={getGender.isError}
        errorFetchText={getGender?.error?.response?.data?.errorMessage[0]}
        loading={getGender.isFetching}
      />
      <Input
        label="No. Hp"
        name="phoneNo"
        placeholder="Cth. 085123456789"
        value={values?.phoneNo}
        onChange={handleChange}
        error={Boolean(errors.phoneNo && touched.phoneNo)}
        errorText={Boolean(errors.phoneNo && touched.phoneNo) && errors.phoneNo}
      />
      <Input
        label="Email"
        name="email"
        type="email"
        placeholder="Cth. example@email.com"
        value={values?.email}
        onChange={handleChange}
        error={Boolean(errors.email && touched.email)}
        errorText={Boolean(errors.email && touched.email) && errors.email}
      />
    </div>
  );
};

const TabAlamat = ({ type }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  // <--- useQuery --
  const getProvinsi = useQuery(
    ["kesiswaan-atribut-provinsi"],
    () => RegionalApi.getProvinsi(axiosPrivate),
    { enabled: Boolean(type === "Form") }
  );

  const getKabupaten = useQuery(
    ["kesiswaan-atribut-kabupaten", values?.address?.provinsiId],
    () =>
      RegionalApi.getKabupaten(axiosPrivate, {
        provinsiId: values?.address?.provinsiId,
      }),
    { enabled: Boolean(values?.address?.provinsiId) }
  );

  const getKecamatan = useQuery(
    ["kesiswaan-atribut-kecamatan", values?.address?.kabupatenId],
    () =>
      RegionalApi.getKecamatan(axiosPrivate, {
        kabupatenId: values?.address?.kabupatenId,
      }),
    { enabled: Boolean(values?.address?.kabupatenId) }
  );

  const getDesa = useQuery(
    ["kesiswaan-atribut-desa", values?.address?.kecamatanId],
    () =>
      RegionalApi.getDesa(axiosPrivate, {
        kecamatanId: values?.address?.kecamatanId,
      }),
    { enabled: Boolean(values?.address?.kecamatanId) }
  );

  return (
    <div className="space-y-5">
      <Select
        label="Provinsi"
        placeholder="Pilih Provinsi"
        name="provinsiId"
        defaultValue={getProvinsi?.data?.find(
          (item) => item.value === values?.address?.provinsiId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              provinsiId: val.value,
              kabupatenId: "",
              kecamatanId: "",
              desaId: "",
            },
          })
        }
        options={getProvinsi?.data ?? []}
        error={Boolean(
          errors?.address?.provinsiId && touched?.address?.provinsiId
        )}
        errorText={
          Boolean(
            errors?.address?.provinsiId && touched?.address?.provinsiId
          ) && errors?.address?.provinsiId
        }
        errorFetch={getProvinsi.isError}
        errorFetchText={getProvinsi?.error?.response?.data?.errorMessage[0]}
        loading={getProvinsi.isFetching}
      />
      <Select
        label="Kabupaten"
        placeholder="Pilih Kabupaten"
        name="kabupatenId"
        defaultValue={getKabupaten?.data?.find(
          (item) => item.value === values?.address?.kabupatenId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              kabupatenId: val.value,
              kecamatanId: "",
              desaId: "",
            },
          })
        }
        options={getKabupaten?.data ?? []}
        error={Boolean(
          errors?.address?.kabupatenId && touched?.address?.kabupatenId
        )}
        errorText={
          Boolean(
            errors?.address?.kabupatenId && touched?.address?.kabupatenId
          ) && errors?.address?.kabupatenId
        }
        errorFetch={getKabupaten.isError}
        errorFetchText={getKabupaten?.error?.response?.data?.errorMessage[0]}
        loading={getKabupaten.isFetching}
        disable={!Boolean(values?.address?.provinsiId)}
      />
      <Select
        label="Kecamatan"
        placeholder="Pilih Kecamatan"
        name="kecamatanId"
        defaultValue={getKecamatan?.data?.find(
          (item) => item.value === values?.address?.kecamatanId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              kecamatanId: val.value,
              desaId: "",
            },
          })
        }
        options={getKecamatan?.data ?? []}
        error={Boolean(
          errors?.address?.kecamatanId && touched?.address?.kecamatanId
        )}
        errorText={
          Boolean(
            errors?.address?.kecamatanId && touched?.address?.kecamatanId
          ) && errors?.address?.kecamatanId
        }
        errorFetch={getKecamatan.isError}
        errorFetchText={getKecamatan?.error?.response?.data?.errorMessage[0]}
        loading={getKecamatan.isFetching}
        disable={!Boolean(values?.address?.kabupatenId)}
      />
      <Select
        label="Desa"
        placeholder="Pilih Desa"
        name="desaId"
        defaultValue={getDesa?.data?.find(
          (item) => item.value === values?.address?.desaId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: { ...values.address, desaId: val.value },
          })
        }
        options={getDesa?.data ?? []}
        error={Boolean(errors?.address?.desaId && touched?.address?.desaId)}
        errorText={
          Boolean(errors?.address?.desaId && touched?.address?.desaId) &&
          errors?.address?.desaId
        }
        errorFetch={getDesa.isError}
        errorFetchText={getDesa?.error?.response?.data?.errorMessage[0]}
        loading={getDesa.isFetching}
        disable={!Boolean(values?.address?.kecamatanId)}
      />
      <TextArea
        label="Alamat"
        name="street"
        value={values?.street}
        rows={4}
        onChange={handleChange}
        error={Boolean(errors.street && touched.street)}
        errorText={Boolean(errors.street && touched.street) && errors.street}
      />
    </div>
  );
};

export const FormSection = ({
  permissions,
  data,
  type,
  setType,
  onSubmit,
  isSubmitting,
}) => {
  return (
    <>
      <HeaderSection
        permissions={permissions}
        data={data}
        type={type}
        setType={setType}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
      <hr />
      <Tabs
        data={[
          {
            title: "Informasi Umum",
            component: <TabInformasiUmum type={type} />,
          },
          {
            title: "Alamat",
            component: <TabAlamat type={type} />,
          },
        ]}
      />
    </>
  );
};
