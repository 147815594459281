import React from "react";
import { Card as FCard } from "flowbite-react";
import { ViewButton } from "components";
import ImageViever from "react-simple-image-viewer";

export const ContentCard = ({
  data,
  onClickDetail,
  index,
  setViewImageConfig,
  viewImageConfig,
  onImageClickHandler,
}) => {
  const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  return (
    <>
      <FCard>
        <img
          alt={data?.title}
          src={data?.imageFileUrl}
          className="h-80 w-full object-cover"
          onClick={(e) => {
            e.stopPropagation();
            onImageClickHandler(data, index);
          }}
        />
        {/* BLOG CATEGORY */}
        <div className="flex flex-row items-center">
          <label
            className={`text-sm py-1 px-3 rounded-md font-bold w-fit`}
            style={{
              color: data?.categoryColorCode,
              backgroundColor: hexToRGB(data?.categoryColorCode, 0.2),
            }}
          >
            {data?.categoryName}
          </label>
          <span className="text-xs flex-1 text-right">{data?.updatedAt}</span>
        </div>
        {/* TITLE */}
        <span className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
          <p>{data?.title}</p>
        </span>
        <ViewButton
          type="button"
          text={`Lihat Detail ${data?.categoryName}`}
          onClick={onClickDetail}
        />
      </FCard>

      {/*Image Viewer  */}
      {viewImageConfig.show && (
        <div style={{ zIndex: 1000 }}>
          <ImageViever
            closeOnClickOutside
            disableScroll
            src={[viewImageConfig.url]}
            onClose={() => setViewImageConfig({ show: false, index: 0 })}
            backgroundStyle={{ zIndex: 10000 }}
          />
        </div>
      )}
    </>
  );
};
