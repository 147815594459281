const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class BerandaApi {
  async getListPrestasi(axiosPrivate, studentPublicId, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${studentPublicId}/achievements/pageable`, { params })
    return fetch.data.payload
  }

  async detail(axiosPrivate, studentPublicId, studentHasAchievementPublicId) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}`)
    return fetch.data.payload
  }

  async getResume(axiosPrivate) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/use-auth/resume`)
    return fetch.data.payload
  }

  createPrestasi(axiosPrivate, studentPublicId, data) {
    return axiosPrivate.post(`${url}/v1/students/${studentPublicId}/achievements`, data)
  }

  updatePrestasi(axiosPrivate, studentPublicId, studentHasAchievementPublicId, data) {
    return axiosPrivate.put(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}`, data)
  }

  deletePrestasi(axiosPrivate, studentPublicId, studentHasAchievementPublicId) {
    return axiosPrivate.delete(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}`)
  }
}

export default new BerandaApi()