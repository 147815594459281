import React from "react";
import { useQuery } from "react-query";
import BlogApi from "../__BlogAPI__";
import { DataStatus } from "components";
import parse from "html-react-parser";

export const DetailSection = ({ modal }) => {
  const { publicId } = modal?.data;

  const getDetail = useQuery(
    ["blog-atribut-detail", publicId],
    () => BlogApi.detail(publicId),
    { enabled: Boolean(modal.show) }
  );

  const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  if (getDetail.isFetching || getDetail.isError) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data pengumuman..."
        text="Pengumuman gagal dimuat"
      />
    );
  } else {
    return (
      <div>
        <div className="flex flex-row items-center">
          <label
            className={`text-sm py-1 px-3 rounded-md font-bold w-fit`}
            style={{
              color: getDetail?.data?.category?.colorCode,
              backgroundColor: hexToRGB(
                getDetail?.data?.category?.colorCode,
                0.2
              ),
            }}
          >
            {getDetail?.data?.category?.name}
          </label>
          <span className="text-xs flex-1 text-right">
            {getDetail?.data?.updatedAt}
          </span>
        </div>
        <div className="text-center text-2xl font-semibold mt-3">
          {/* JUDUL */}
          {getDetail?.data?.title}
        </div>
        <div className="text-center text-sm">
          {/* AUTHOR */}
          Dibuat oleh. {getDetail?.data?.user?.name}
        </div>
        <hr className="mt-5" />
        <div className="mt-5 mb-5">{parse(getDetail?.data?.body)}</div>
      </div>
    );
  }
};
