import { axiosPublic } from "../../../services";

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class BlogApi {
  async getPageable(params) {
    const fetch = await axiosPublic.get(`${url}/v1/blog/pageable`, { params });
    return fetch.data.payload;
  }

  async detail(blogPublicId) {
    const fetch = await axiosPublic.get(`${url}/v1/blog/${blogPublicId}`);
    return fetch.data.payload;
  }
}

export default new BlogApi();
