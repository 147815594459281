import React from 'react'

export const TextArea = ({
    label,
    name,
    className,
    value,
    rows,
    readOnly,
    onChange,
    error,
    errorText,
    valid,
    validText
}) => {
    return (
        <div>
            <small>
                {label}
            </small>
            <textarea 
                name={name}
                value={value}
                className={`${className ? className : 'input-primary mt-2'}`}
                readOnly={readOnly}
                onChange={onChange}
                rows={rows}
            />
            {error && <div>
                <small className='validation-error-text'>
                    {errorText}
                </small>
            </div>}
            {valid && <div>
                <small className='validation-success-text'>
                    {validText}
                </small>
            </div>}
        </div>
    )
}
