import DataTable from 'react-data-table-component'

export const Table = ({
    columns,
    data,
    loading,
    error,
    pagination,
    totalRows,
    handlePerRowsChange,
    handlePageChange,
    paginationRowsPerPageOptions = [10, 15, 20, 25, 30]
}) => {
    const customStyles = {
        rows: {
            style: {
                minHeight: '40px', // override the row height
                paddingTop: '7px',
                paddingBottom: '7px'
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
            },
        },
    };

    return (
        <DataTable
            responsive
            customStyles={customStyles}
            columns={columns}
			data={data}
			progressPending={loading}
            progressComponent={<small className='font-semibold'>Memuat data...</small>}
			pagination={pagination}
			paginationServer={pagination}
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
            highlightOnHover
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            noDataComponent={<small className='validation-error-text'>{`${error !== undefined ? error : 'Data tidak ditemukan'}`}</small>}
		/>
    )
}