import * as Yup from 'yup'

export const formValidationSchema = () => {
  return Yup.object().shape({
    achievementLibraryPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    achievementRegionalPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    achievementParticipationPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    activityDate: Yup.date()
      .required('Tanggal Kegiatan wajib diisi'),
    name: Yup.string()
      .required('Nama Kegiatan wajib diisi')
  })
}