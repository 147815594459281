import { Card, RowDetailLayout, Tabs } from "components";
import { getDashDMY } from "utilities";
import { HeaderSection } from ".";

const TabInformasiUmum = ({ data }) => {
  return (
    <div className="space-y-5">
      <RowDetailLayout label="NIS (Nomor Induk Siswa)" text={data?.nis} />
      <RowDetailLayout
        label="Tanggal Lahir"
        text={data?.dateOfBirth ? getDashDMY(data?.dateOfBirth) : "-"}
      />
      <RowDetailLayout
        label="Jenis Kelamin"
        text={data?.gender === "P" ? "Perempuan" : "Laki-Laki"}
      />
      <RowDetailLayout label="No. Hp" text={data?.phoneNo} />
      <RowDetailLayout label="Email" text={data?.email} />
    </div>
  );
};

const TabAlamat = ({ data }) => {
  return (
    <div className="space-y-5">
      <RowDetailLayout
        label="Provinsi"
        text={data?.address?.desa?.kecamatan?.kabupaten?.provinsi?.nama}
      />
      <RowDetailLayout
        label="Kabupaten"
        text={data?.address?.desa?.kecamatan?.kabupaten?.nama}
      />
      <RowDetailLayout
        label="Kecamatan"
        text={data?.address?.desa?.kecamatan?.nama}
      />
      <RowDetailLayout label="Desa" text={data?.address?.desa?.nama} />
      <RowDetailLayout label="Alamat" text={data?.address?.street} />
    </div>
  );
};

const TabInformasiKelas = ({ data }) => {
  const { studentHasClasses } = data;

  return (
    <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
      {studentHasClasses?.map((val, index) => {
        return (
          <Card
            key={index}
            title={`Kelas ${val?.classroom?.grade?.name}-${val?.classroom?.name}`}
            body={
              <>
                <RowDetailLayout label="Tahun Ajaran" text={val?.periode} />
                <RowDetailLayout
                  label="Guru Wali Kelas"
                  text={val?.classroom?.teacherGuide?.name}
                />
                <RowDetailLayout
                  label="Guru Bimbingan Konseling"
                  text={val?.classroom?.grade?.counseling?.name}
                />
              </>
            }
          />
        );
      })}
    </div>
  );
};

export const DetailSection = ({ permissions, data, type, setType }) => {
  return (
    <>
      <HeaderSection
        permissions={permissions}
        data={data}
        type={type}
        setType={setType}
      />
      <hr />
      <Tabs
        data={[
          {
            title: "Informasi Umum",
            component: <TabInformasiUmum data={data} />,
          },
          {
            title: "Alamat",
            component: <TabAlamat data={data} />,
          },
          {
            title: "Informasi Kelas",
            component: <TabInformasiKelas data={data} />,
          },
        ]}
      />
    </>
  );
};
