/* eslint-disable import/no-anonymous-default-export */
import logo from "../assets/image/logo.png";
import { LihatProfil } from "views/LihatProfil";
import { UbahPassword } from "views/UbahPassword";
import { Beranda } from "views/Beranda";
import { Blog } from "views/Blog";
import { KebijakanPrivasiPublicPage } from "views/Addition/KebijakanPrivasiPublicPage";

export default {
  LOGO: logo,
  MENU: [
    {
      name: "Beranda",
      link: "/",
      type: "menu",
      permissions: [],
    },
    {
      name: "Beranda",
      link: "/beranda",
      type: "menu",
      permissions: ["STUDENT", "ALUMNI"],
    },
    {
      name: "Kegiatan",
      link: "/kegiatan",
      type: "menu",
      permissions: ["ACHIEVEMENT_R"],
    },
  ],
  PROFILE_DROPDOWN: [
    {
      name: "Lihat Profil",
      link: "/profil",
    },
    {
      name: "Ubah Password",
      link: "/ubah-password",
    },
    {
      name: "Panduan",
      link: "https://drive.google.com/file/d/1ZXxr2DlPRGiWW0GCHVjUWZ6VLojt6Tvh/view?usp=sharing",
      external: true,
    },
    {
      name: "Keluar",
      link: undefined,
    },
  ],
  ROUTES: [
    {
      path: "/",
      page: <Blog />,
      name: "Beranda",
      permissions: [],
    },
    {
      path: "/beranda",
      page: <Blog />,
      name: "Beranda",
      permissions: [],
    },
    {
      path: "/kebijakan-privasi",
      page: <KebijakanPrivasiPublicPage />,
      name: "Kebijakan Privasi",
      permissions: [],
    },
    {
      path: "/kegiatan",
      page: <Beranda />,
      name: "Kegiatan",
      permissions: ["STUDENT", "ALUMNI"],
    },
    {
      path: "/ubah-password",
      page: <UbahPassword />,
      name: "Ubah Password",
      permissions: [],
    },
    {
      path: "/profil",
      page: <LihatProfil />,
      name: "Profil",
      permissions: [],
    },
  ],
};
