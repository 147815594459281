import { SchoolsApi } from "api"
import { useQuery } from "react-query"
import { useAxiosPrivate } from "utilities"
import Config from "config"

export const HeaderKop = () => {
  const axiosPrivate = useAxiosPrivate()

  // <--- useQuery --->
  const getAkunSekolah = useQuery(
      ['kesiswaan-atribut-akun-sekolah'],
      () => SchoolsApi.getAccount(axiosPrivate)
  )
  return (
    <div className="flex flex-row p-2 border-b-2 border-black justify-center">
      {/* <-- LOGO --> */}
      <img
        className="h-24"
        src={Config.LOGO}
        alt="Logo"
      />
      {/* <--- INFO SEKOLAH --> */}
      <div className="text-center ml-5">
        <p className="text-xs tracking-wide font-semibold">YAYASAN PANGUDI LUHUR PERWAKILAN YOGYAKARTA</p>
        <p className="text-2xl font-bold uppercase tracking-tight">{getAkunSekolah?.data?.name}</p>
        <p className="text-sm font-semibold">TERAKREDITASI "A"</p>
        <div className="flex flex-row text-[10px] text-left justify-center">
          <div>
            <p>Alamat: {`${getAkunSekolah?.data?.address?.street} ${getAkunSekolah?.data?.address?.desa?.kecamatan?.kabupaten?.nama} ${getAkunSekolah?.data?.address?.postalCode}`}</p>
            <p>Website: {getAkunSekolah?.data?.website}</p>
          </div>
          <div className="ml-3">
            <p>Telp: {getAkunSekolah?.data?.telp},  Fax. {getAkunSekolah?.data?.fax}</p>
            <p>Email: {getAkunSekolah?.data?.email}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
