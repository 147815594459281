import { CancelButton, DeleteButon, SaveButton } from "../.."

export const Modal = ({
  children,
  id,
  show,
  size,
  header,
  type,
  isSubmitting,
  onSubmit,
  onHide
}) => {
  return (
    <div id={id} className={`relative z-10 ${!show && 'hidden'}`}>
      <div className="fixed inset-0 bg-clobasoft-dark-gray bg-opacity-50 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex sm:min-h-full items-center justify-center p-5">
          <div 
            className={`
              relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 w-full 
              ${size === 'small' && 'lg:w-2/6 md:w-2/3 sm:w-11/12'} ${size === 'medium' && 'lg:w-2/4 sm:w-11/12'} ${size === 'large' && 'sm:w-11/12'}
            `}
          >
            <div className="bg-white">
              <div className="flex flex-col items-start">
                {/* <--- HEADER ---> */}
                <div className='flex justify-between items-center border-b-[1px] rounded-t-md w-full px-8 py-6'>
                  <h3 className='text-lg font-bold text-clobasoft-dark-gray'>{header}</h3>
                  <button type="button" onClick={onHide} className="custom-transition text-clobasoft-dark-gray bg-transparent hover:bg-gray-200 hover:text-clobasoft-dark-gray rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                  </button>
                </div>
                {/* <--- BODY ---> */}
                <div className="px-8 py-6 sm:mt-0 w-full">
                  {children}
                </div>
                {/* <--- FOOTER ---> */}
                <div className="sm:flex sm:px-6 border-t-[1px] w-full px-8 py-6 sm:justify-end">
                  {
                    (type === 'create' || type === 'update') && 
                      <SaveButton 
                        disabled={isSubmitting} 
                        loading={isSubmitting}
                        type='submit'
                        onClick={onSubmit} 
                        className='w-full sm:ml-3 sm:w-auto'
                      />
                  }
                  {
                    type === 'delete' && 
                      <DeleteButon 
                        disabled={isSubmitting} 
                        loading={isSubmitting}
                        type='submit'
                        onClick={onSubmit} 
                        className='w-full sm:ml-3 sm:w-auto'
                      />
                  }
                  <CancelButton 
                    disabled={isSubmitting}
                    onClick={onHide}
                    type={type}
                    text={type === 'read' ? 'Tutup' : 'Batal'}
                    className={`${type !== 'read' && 'btn-light'} w-full sm:ml-3 sm:w-auto`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
